import React from 'react';
import { AD_SIZE, AD_SLOT } from '../../constants';
import type { AdFormat } from '../../types';

type BannerAdProps = {
  format?: AdFormat;
  slot?: AD_SLOT;
};

const BannerAd: React.FC<BannerAdProps> = (props) => {
  const { height, width } = AD_SIZE[props.format];

  return (
    <ins
      className="adsbygoogle"
      data-ad-client="ca-pub-9481184553230114"
      style={{
        display: 'block',
        height,
        width,
      }}
      data-ad-slot={props.slot || AD_SLOT.BANNER_DEFAULT}
      data-full-width-responsive="true"
    />
  );
};

BannerAd.defaultProps = {
  format: 'W300H250',
  slot: null,
};

export default BannerAd;
