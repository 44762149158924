import React from 'react';
import { Helmet } from 'react-helmet-async';
import { SW } from '@types';
import buildHeadMetaConfig from './buildHeadMetaConfig';
import { useUrlMethods } from '../../../../context/withUrlMethods';

interface IHeadMetaProps {
  config?: SW.Models.IHeadMeta;
}

const HeadMeta: React.FC<IHeadMetaProps> = ({ config }) => {
  const { location } = useUrlMethods();
  const { title, link, meta, script } = buildHeadMetaConfig(config, location);

  return (
    <Helmet>
      <title>{title}</title>
      {link.canonical && <link href={link.canonical} rel="canonical" />}
      {Object.keys(meta).map((name) => (
        <meta key={name} name={name} content={meta[name]} />
      ))}
      {Object.keys(script).map((key) => (
        <script key={key} type="application/ld+json">
          {script[key]}
        </script>
      ))}
    </Helmet>
  );
};

HeadMeta.defaultProps = {
  config: null,
};

export default HeadMeta;
