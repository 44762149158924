export const GOOGLE_ADS_SDK =
  'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

export const AD_ENVS = ['production', 'stage'];

export enum AD_SLOT {
  BANNER_DEFAULT = 4200730310,
  IN_FEED_DEFAULT = 1255320627,
  BANNER_INSTEAD_OPUB = 6681217368,
}

export const AD_SIZE = {
  W300H250: {
    width: 300,
    height: 250,
  },
  W728H90: {
    width: 728,
    height: 90,
  },
};

export const MOBILE_SIDEBAR_ANIMATION_TIME = 500;
