import React from 'react';
import { AD_SLOT } from '../../constants';

type InFeedAdProps = {
  slot?: AD_SLOT;
};

const InFeedAd: React.FC<InFeedAdProps> = (props) => {
  return (
    <ins
      className="adsbygoogle"
      data-ad-client="ca-pub-9481184553230114"
      style={{
        display: 'block',
        width: '100%',
      }}
      data-ad-format="fluid"
      data-ad-slot={props.slot || AD_SLOT.IN_FEED_DEFAULT}
      data-ad-layout-key="-gr-2m-t-2u+na"
    />
  );
};

InFeedAd.defaultProps = {
  slot: null,
};

export default InFeedAd;
